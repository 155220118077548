import React from 'react';
import { Link } from 'gatsby';
import '../style/project.scss';
import Layout from '../layouts/en';
import Gallery from '../components/gallery';

const Template = (props) => {
  const {
    pathOriginal,
    title,
    body,
    projectBefore,
    projectAfter,
  } = props.pageContext;
  return (
    <Layout location={props.location}>
      {/* Pour dekstop */}
      <div className="row d-md-flex d-none project__page project__page--desktop">
        <div className="col-3 project__page__details">
          <div className="project__page__other">
            <Link
              to={`/en/projects/${projectBefore}`}
              className="project__page__other--prev"
            >
              <img src={'/fleche.svg'} alt="previous" />
            </Link>
            <span>Projet</span>
            <Link
              to={`/en/projects/${projectAfter}`}
              className="project__page__other--next"
            >
              <img src={'/fleche.svg'} alt="next" />
            </Link>
          </div>
          <h1 className="project__page__title">{title}</h1>
          <div
            className="project__page__informations"
            dangerouslySetInnerHTML={{ __html: body }}
          ></div>
        </div>
        <div className="col-9 project__page__gallery">
          <Gallery path={pathOriginal} />
        </div>
      </div>

      {/* Pour Mobile */}
      <div className="row d-md-none d-flex project__page project__page--mobile">
        <div className="col-12 project__page__details">
          <div className="title__mobile">
            <Link
              to={`/en/projects/${projectBefore}`}
              className="project__page__other--prev"
            >
              <img src={'/fleche.svg'} alt="previous" />
            </Link>
            <h1 className="project__page__title">{title}</h1>
            <Link
              to={`/en/projects/${projectAfter}`}
              className="project__page__other--next"
            >
              <img src={'/fleche.svg'} alt="next" />
            </Link>
          </div>
        </div>
        <div className="col-12 project__page__gallery">
          <Gallery path={pathOriginal} />
        </div>
        <div className="col-12">
          <div
            className="project__page__informations"
            dangerouslySetInnerHTML={{ __html: body }}
          ></div>
        </div>
      </div>
    </Layout>
  );
};

export default Template;
